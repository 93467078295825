import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import { Link } from 'react-router-dom';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Custom Dyno Tuning',
    anchor: 'custom-dyno-tuning',
    paragraph: 'With both an all wheel drive Dyno Dynamics chassis dyno and Mainline hub dyno in house and over 20 years experience tuning everything from the street to the track, Godzilla Motorsport has the tools and experience to service all of your tuning needs.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Custom Dyno Tuning
                  </div>
                <h3 className="mt-0 mb-12">
                  Diesel Tuning
                  </h3>
                <p className="m-0">
                  Looking to maximize the power and efficiency of your diesel engine?
                  Godzilla Motorsport offer a tailored tuning solutions that is specifically designed to optimize the
                  performance of your diesel vehicle. Whether you're looking for increased horsepower, improved fuel economy,
                  or enhanced torque, Godzilla Motorsport will unlock its full potential. Experience smoother
                  acceleration, better throttle response, and overall improved drivability. <Link to="/contact">Contact us</Link> today
                  and unleash the true power of your diesel engine.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/content/dieseldyno2.webp')}
                  alt="Features split 01"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Custom Dyno Tuning
                  </div>
                <h3 className="mt-0 mb-12">
                  Performance Tuning
                  </h3>
                <p className="m-0">
                  Whether you're a street car enthusiast or a professional racer, Godzilla Motorsport are equipped with
                  the knowledge and experience to extract every ounce of power and performance from your engine. We
                  specialize in fine-tuning all aspects of your engine, including fuel mapping, ignition timing, and
                  air-to-fuel ratios, to deliver optimal horsepower, torque, and throttle response. Unleash the true
                  potential of your engine and leave your competitors in the dust. <Link to="/contact">Contact us</Link> today to schedule your
                  custom engine tuning and take your street and race performance to new heights.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/content/dynotune.webp')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;