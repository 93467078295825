import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import GenericSection from "../components/sections/GenericSection";

const Home = () => {

    return (
        <>
            <Hero />
            <GenericSection className='contact'>
                <h2 className="mt-0 mb-16">Contact</h2>
                <p>P: <a href="tel:+61738904488">(07) 3890 4488</a></p>
                <p>E: <a href="mailto:sales@godzillamotorsport.com">sales@godzillamotorsport.com</a></p>
                <p>A: 11/20 Ingleston Rd, Wakerley QLD 4154</p>
            </GenericSection>
        </>
    );
}

export default Home;