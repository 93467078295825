import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import {Link} from "react-router-dom";

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Performance Parts',
    anchor: 'Performance Parts'.toLowerCase().replace(/\s/g, "-"),
    paragraph: 'Looking to enhance your performance? Godzilla Motorsport can supply all of the performance parts to propel your vehicle to new heights. Whether you\'re a professional driver or a passionate enthusiast our comprehensive range of parts from the biggest brands will give you the edge you need.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/brands/link.svg')}
                      alt="Link Engine Management"
                      width={173}
                      height={80} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <p className="text-sm">
                    Link Engine Management is a global leader in motorsport electronics and performance technology.
                  </p>
                  <Link to={{pathname: "https://performancepartsecus.com/collections/link-engine-management"}} className="button">Shop now</Link>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/brands/turbosmart-logo.png')}
                      alt="TurboSmart"
                      width={200}
                      height={31} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <p className="text-sm">
                    Turbosmart is an Australian Manufacturer of turbo-performance products that are designed & engineered in-house. Engineered to Win!
                  </p>
                  <Link to={{pathname: "https://performancepartsecus.com/collections/turbosmart"}} className="button">Shop now</Link>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/brands/emtron-australia.png')}
                      alt="Emtron Australia"
                      width={200}
                      height={61} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <p className="text-sm">
                    Emtron is a range of high end engine management systems designed for a market that requires uncompromising flexibility.
                  </p>
                  <Link to={{pathname: "https://performancepartsecus.com/collections/emtron-australia"}} className="button">Shop now</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;